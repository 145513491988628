import { forwardRef, useCallback, useEffect, useMemo } from "react"

function InputProxy(
  { onChange = null, value = null, defaultValue = null, nativeOnChange = false, ...otherProps },
  inputRef
) {
  const makeOnChangeHandler = useCallback(() => {
    const _handler = onChange ?? null

    if (_handler === null) {
      return (event) => {
        event.stopPropagation()
        event.preventDefault()
      }
    }

    return (event) => {
      _handler(event)
    }
  }, [onChange])

  const onChangeHandler = useMemo(() => {
    return makeOnChangeHandler()
  }, [makeOnChangeHandler])

  useEffect(() => {
    const _ref = inputRef.current

    if (nativeOnChange) {
      _ref?.addEventListener("change", onChangeHandler)
    }

    return () => {
      _ref?.removeEventListener("change", onChangeHandler)
    }
  }, [onChangeHandler, inputRef.current, nativeOnChange])

  const valueProps = useMemo(() => {
    const _makeValueProps = () => {
      const _value = value ?? null
      const _defaultValue = defaultValue ?? ""

      if (_value === null) {
        return {
          defaultValue: _defaultValue
        }
      }

      if (!nativeOnChange) {
        return {
          value
        }
      }

      return {
        defaultValue: value
      }
    }

    const _makeOnChangeProps = () => {
      if (nativeOnChange) {
        return {}
      }

      return {
        onChange: onChangeHandler
      }
    }

    return {
      ..._makeValueProps(),
      ..._makeOnChangeProps()
    }
  }, [value, defaultValue, nativeOnChange, onChangeHandler])

  return <input ref={inputRef} {...valueProps} {...otherProps} />
}

export default forwardRef(InputProxy)
