import cx from "classnames"
import { createContext, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import ErrorBoundary from "../../errors/ErrorBoundary"
import Helptip from "../Helptip"
import styles from "./style.module.scss"

export const FormFieldContext = createContext(null)

export default function FormField({
  children,
  label = null,
  labelTooltip = null,
  isLoading = false,
  positionLabel = "up",
  inputWrapperStyle = {},
  bold = true,
  color = "#424242",
  twoPoints = true,
  checkbox = false,
  border = true,
  required = false,
  extraAlert = null,
  classRequired = false,
  disabled = false,
  className = null,
  outline = "thick",
  labelStyle = {},
  labelClassName = null,
  style = {}
}) {
  let posLabelClassName
  if (positionLabel === "up") {
    posLabelClassName = `${styles.formFieldPosLabelUp}`
  } else if (positionLabel === "right") {
    posLabelClassName = `${styles.formFieldPosLabelRight}`
  } else if (positionLabel === "left") {
    posLabelClassName = `${styles.formFieldPosLabelLeft}`
  }

  const { t } = useTranslation()
  const infixRef = useRef(null)
  const [focus, setFocus] = useState(false)
  const [isMultiple, setIsMultiple] = useState(false)
  const [optionsRef, setOptionsRef] = useState(null)

  useEffect(() => {
    const inputFocusHandler = () => {
      setFocus(true)
    }

    const inputBlurHandler = () => {
      setFocus(false)
    }

    const inputRef = infixRef.current.firstChild

    if (inputRef) {
      inputRef.addEventListener("focus", inputFocusHandler)
      inputRef.addEventListener("blur", inputBlurHandler)
    }

    return () => {
      if (inputRef) {
        inputRef.removeEventListener("focus", inputFocusHandler)
        inputRef.removeEventListener("focus", inputBlurHandler)
      }
    }
  }, [])

  const getWrapperStyles = () => {
    let wrapperStyle = {}

    if (isMultiple) {
      switch (positionLabel) {
        case "left":
          wrapperStyle = {
            gridTemplateColumns: "max-content max-content"
          }
          break
        case "up":
          wrapperStyle = {
            gridTemplateRows: "auto 1fr"
          }
          break
      }
    }
    return Object.assign({}, style, wrapperStyle)
  }

  const getBoldLabel = () => {
    let style = {}
    if (bold) {
      style = { fontWeight: "bold", color: color }
    } else {
      style = { fontWeight: "100" }
    }
    return style
  }

  const optionsWrapperRef = useCallback((node) => {
    if (!!node) {
      setOptionsRef(node)
    }
  }, [])

  const renderLabel = () => {
    return !!label ? (
      <label
        className={labelClassName}
        style={{ ...getBoldLabel(), alignSelf: "center", marginBottom: 2, ...labelStyle }}
      >
        {typeof label === "string" ? t(label, label) : label}
        {twoPoints && ":"}
        {required && <span className={styles.asterisk}> *</span>}
        {labelTooltip && <Helptip name={labelTooltip} title={label} />}
      </label>
    ) : null
  }

  return (
    <ErrorBoundary>
      <FormFieldContext.Provider value={{ setIsMultipleContext: setIsMultiple, optionsWrapperRefContext: optionsRef }}>
        <div
          className={cx(
            className,
            styles.formField,
            "formField field",
            posLabelClassName,
            focus && styles.active,
            disabled && "disabled",
            disabled && styles.disabled,
            {
              loading: isLoading
            }
          )}
          style={getWrapperStyles()}
        >
          {positionLabel !== "right" && renderLabel()}

          <div className={cx(styles.formFieldFlex, "formFieldFlex")}>
            <div
              className={[
                styles.formFieldOutline,
                outline == "thick" && styles.formFieldOutlineThick,
                outline == "thick" && "outlineThick",
                classRequired && `${styles.formFieldRequired}`
              ].join(" ")}
              style={{
                border: !border && "none",
                ...style
              }}
            ></div>
            <div
              className={styles.formFieldOutline}
              style={{
                color: checkbox ? "#fff" : "#e6e6e6",
                border: !border && "none"
              }}
            ></div>
            <div className={styles.formFieldInfix} style={inputWrapperStyle} ref={infixRef}>
              {children}
            </div>
          </div>
          {isMultiple && (
            <>
              {positionLabel === "left" && <div></div>}
              <div className={styles.optionsWrapper} ref={optionsWrapperRef}></div>
            </>
          )}
          {positionLabel == "right" && renderLabel()}

          {extraAlert && (
            <span className={styles.asterisk} style={{ fontWeight: "bold" }}>
              {extraAlert}
            </span>
          )}
        </div>
      </FormFieldContext.Provider>
    </ErrorBoundary>
  )
}
