import ReactNumberFormat from "react-number-format"

export default function NumberFormat(props = {}) {
  let userSettings = props.currencyFormat
    ? props.currencyFormat
    : typeof window !== "undefined"
    ? JSON.parse(localStorage.getItem("currencyFormat"))
    : {
        symbol: "$ ",
        thousandSeparator: ".",
        decimalSeparator: ",",
        decimals: 0
      }

  if (userSettings === null) {
    userSettings = {
      symbol: "$ ",
      thousandSeparator: ".",
      decimalSeparator: ",",
      decimals: 0
    }
  }

  const componentProps = {
    prefix: userSettings.symbol ?? "$",
    thousandSeparator: userSettings.thousandSeparator ?? ".",
    decimalSeparator: userSettings.decimalSeparator ?? ",",
    decimalScale: userSettings.decimals ?? 0,
    ...props
  }

  if (props.value) {
    const v = parseFloat(props.value)
    if (!isNaN(v)) {
      componentProps.value = v
    }
  }

  return <ReactNumberFormat fixedDecimalScale={true} {...componentProps} />
}
