import cx from "classnames"
import { useCallback, useRef, useState } from "react"
import ReactNumberFormat from "react-number-format"
import { Icon as IconSemantic } from "semantic-ui-react"
import NumberFormat from "src/components/NumberFormat"
import InputProxy from "src/components/_proxies/Input"
import useTranslation from "src/hooks/useTranslation"
import Icon from "../../../components/Icon"

import Tooltip from "../../Tooltip"
import styles from "./style.module.scss"

export default function Input({
  placeholder = null,
  type = "text",
  isNumber = false,
  className = null,
  decimals = 2,
  autoFocus,
  icon = "",
  min = 0,
  max,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  initialValue,
  colorIcon = "#e6e6e6",
  prefix = "",
  suffix = "",
  format = "",
  tooltip = false,
  disabled = false,
  readOnly = false,
  length = null,
  typeLimit = null,
  typeMask = null,
  cleanZero = true,
  style = {},
  nativeOnChange = false,
  inputAttrs = {}
}) {
  const classPaddingLeft = icon ? `${styles.paddingLeft}` : ""

  const [typePass, setTypePass] = useState(type)

  const { t } = useTranslation()

  if (isNumber && !isNaN(parseFloat(initialValue))) {
    initialValue = parseFloat(initialValue) + 0
  } else if (type == "number" && !isNaN(parseFloat(initialValue))) {
    initialValue = parseFloat(initialValue) + 0
  }

  const [inputValue, setInputValue] = useState(
    !isNumber && type != "number" && initialValue === 0 && cleanZero ? "" : initialValue ?? ""
  )
  const inputRef = useRef()

  const [pasted, setPasted] = useState(false)

  if (disabled || readOnly) {
    placeholder = null
  }

  const onChangeHandler = useCallback(
    (event) => {
      if (disabled || readOnly) {
        return
      }

      const val = event?.target?.value ?? ""

      switch (type) {
        case "number":
          if (!pasted) {
            if (max == null || val <= max) {
              setInputValue(val)
            }
          }

          setPasted(false)
          break
        default:
          setInputValue(val)
          break
      }

      if (type === "number" && val === "") {
        onChange(null)
      } else {
        onChange(val)
      }
    },
    [disabled, max, onChange]
  )

  const onNumberFormatValueChange = useCallback((values) => {
    setInputValue(values["formattedValue"])
    onChange(values["formattedValue"], values["value"])
  }, [])

  const onPaste = useCallback((event) => {
    if (Number(event.clipboardData.getData("Text")) < 0) {
      setPasted(true)
    }
  }, [])

  const onKeyDown = useCallback((event) => {
    if (event.keyCode === 69 || event.keyCode === 189) event.preventDefault()
  }, [])

  const validateValueFn = useCallback((inputObj) => {
    switch (typeLimit) {
      case "hour":
        const { value } = inputObj
        const hour = value.substring(0, 2)
        const minutes = value.substring(2, 4)

        return hour <= 23 && minutes <= 59
      default:
        if (isNumber) {
          const { value } = inputObj
          const _value = value === "" ? 0.0 : parseFloat(value)

          if (isNaN(_value)) {
            return false
          }

          if (min !== undefined && min !== null && min > _value) {
            return false
          }

          if (max !== undefined && max !== null && max < _value) {
            return false
          }
        }

        return true
    }
  }, [])

  const formatMask = useCallback(() => {
    switch (typeMask) {
      case "hour":
        return ["H", "H", "M", "M"]
      default:
        return
    }
  }, [typeMask])

  let tooltipContent = !isNumber ? (
    type === "number" ? (
      <>
        <InputProxy
          className={cx(classPaddingLeft, className, disabled && style.disabled)}
          type={type}
          placeholder={placeholder}
          value={inputValue}
          ref={inputRef}
          onChange={onChangeHandler}
          min={min}
          max={max}
          disabled={disabled}
          readOnly={readOnly}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          style={style}
          nativeOnChange={nativeOnChange}
          {...inputAttrs}
        />
        <span>{suffix}</span>
      </>
    ) : (
      <>
        <InputProxy
          className={cx(classPaddingLeft, className, disabled && style.disabled)}
          type={type}
          placeholder={placeholder}
          value={inputValue}
          ref={inputRef}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChangeHandler}
          style={style}
          nativeOnChange={nativeOnChange}
          {...inputAttrs}
          {...(length !== null && { maxLength: length })}
        />
        <span>{suffix}</span>
      </>
    )
  ) : isNumber === "currency" ? (
    <NumberFormat
      suffix={suffix}
      value={inputValue}
      disabled={disabled}
      placeholder={placeholder}
      displayType={readOnly ? "text" : "input"}
      onValueChange={onNumberFormatValueChange}
      isAllowed={validateValueFn}
      currencyFormat={format === "" ? null : format}
      mask={formatMask()}
      className={className}
    />
  ) : format !== "" ? (
    <NumberFormat
      format={format}
      value={inputValue}
      suffix={suffix}
      placeholder={placeholder}
      disabled={disabled}
      displayType={readOnly ? "text" : "input"}
      onValueChange={onNumberFormatValueChange}
      isAllowed={validateValueFn}
      mask={formatMask()}
      className={className}
    />
  ) : (
    <ReactNumberFormat
      suffix={suffix}
      value={inputValue}
      disabled={disabled}
      displayType={readOnly ? "text" : "input"}
      placeholder={placeholder}
      onValueChange={onNumberFormatValueChange}
      isAllowed={validateValueFn}
      mask={formatMask()}
      thousandSeparator='.'
      decimalSeparator=','
      decimalScale={decimals}
      prefix={prefix}
      className={className}
    />
  )

  if (disabled) {
    tooltipContent = <div>{tooltipContent}</div>
  }

  return tooltip ? (
    <Tooltip content={<h3>{typeof tooltip == "string" ? tooltip : inputValue}</h3>}>
      {icon ? (
        <div className={styles.boxIcon}>
          <Icon name={icon} color={colorIcon} />
        </div>
      ) : null}
      {tooltipContent}
    </Tooltip>
  ) : (
    <>
      {icon ? (
        <div className={styles.boxIcon}>
          <Icon name={icon} color={colorIcon} />
        </div>
      ) : null}
      {!isNumber ? (
        type === "number" ? (
          <>
            <InputProxy
              className={cx(classPaddingLeft, className, disabled && style.disabled)}
              type={type}
              placeholder={placeholder}
              ref={inputRef}
              value={inputValue}
              onChange={onChangeHandler}
              min={min}
              max={max}
              disabled={disabled}
              readOnly={readOnly}
              onKeyDown={onKeyDown}
              onPaste={onPaste}
              style={style}
              nativeOnChange={nativeOnChange}
              {...inputAttrs}
            />
            <span>{suffix}</span>
          </>
        ) : (
          <>
            <div style={{ display: "flex", alignItems: "center", ...style }}>
              <InputProxy
                autoFocus={autoFocus}
                onBlur={onBlur}
                onFocus={onFocus}
                className={cx(classPaddingLeft, className, disabled && style.disabled)}
                type={typePass}
                placeholder={placeholder}
                ref={inputRef}
                value={inputValue}
                disabled={disabled}
                readOnly={readOnly}
                onChange={onChangeHandler}
                style={style}
                nativeOnChange={nativeOnChange}
                {...inputAttrs}
                {...(length !== null && { maxLength: length })}
              />
              {type === "password" && (
                <>
                  <Tooltip
                    content={
                      typePass === "password"
                        ? t("frontend.user.actions.view_password_eye")
                        : t("frontend.user.actions.hide_password_eye")
                    }
                    className='disabled-op'
                    size='small'
                    position='above'
                  >
                    <div>
                      {typePass === "password" && (
                        <IconSemantic
                          name='eye slash'
                          style={{ cursor: "pointer" }}
                          onClick={() => setTypePass("text")}
                        />
                      )}
                      {typePass === "text" && (
                        <IconSemantic
                          name='eye'
                          style={{ cursor: "pointer" }}
                          onClick={() => setTypePass("password")}
                        />
                      )}
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
            <span>{suffix}</span>
          </>
        )
      ) : isNumber === "currency" ? (
        <NumberFormat
          suffix={suffix}
          value={inputValue}
          placeholder={placeholder}
          disabled={disabled || readOnly}
          displayType={readOnly ? "text" : "input"}
          onValueChange={onNumberFormatValueChange}
          isAllowed={validateValueFn}
          currencyFormat={format === "" ? null : format}
          mask={formatMask()}
          className={className}
        />
      ) : format !== "" ? (
        <NumberFormat
          format={format}
          value={inputValue}
          suffix={suffix}
          placeholder={placeholder}
          disabled={disabled}
          displayType={readOnly ? "text" : "input"}
          onValueChange={onNumberFormatValueChange}
          isAllowed={validateValueFn}
          mask={formatMask()}
          className={className}
        />
      ) : (
        <ReactNumberFormat
          suffix={suffix}
          value={inputValue}
          disabled={disabled}
          displayType={readOnly ? "text" : "input"}
          placeholder={placeholder}
          onValueChange={onNumberFormatValueChange}
          isAllowed={validateValueFn}
          mask={formatMask()}
          thousandSeparator='.'
          decimalSeparator=','
          decimalScale={decimals}
          prefix={prefix}
          className={className}
        />
      )}
    </>
  )
}
