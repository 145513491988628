import React from "react"
import { Trans, useTranslation } from "react-i18next"
import Icon from "src/components/Icon"
import Tooltip from "src/components/Tooltip"

export default function Helptip({
  name,
  children = null,
  title = null,
  inline = true,
  iconSize = 16,
  iconColor = "#a8cf45",
  maxWidth = 400,
  tooltipPosition = "below",
  tooltipStyle = {},
  useHTML = false,
  translate = true
}) {
  const { i18n } = useTranslation()

  if (translate) {
    name = "help." + name

    if (!i18n.exists(name)) {
      return null
    }
  } else if (!name) {
    return null
  }

  return (
    <Tooltip
      title={title}
      content={translate ? <Trans i18nKey={name} /> : name}
      inline={inline}
      position={tooltipPosition}
      mode='help'
      maxWidth={maxWidth}
      useHTML={useHTML}
      containerStyle={{ cursor: "help", ...tooltipStyle }}
    >
      {children ? (
        children
      ) : (
        <span style={{ display: "inline-block", marginRight: 2, marginLeft: 2 }}>
          <Icon name='help' size={iconSize} color={iconColor} />
        </span>
      )}
    </Tooltip>
  )
}
